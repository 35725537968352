.imgStyle:hover {
  transform: scale(1.5);
  z-index: 10000000 !important;
}

.selected{
  padding: 10px;
  background-color: #e01515d3;
  box-shadow: 0px 2px 13px -2px rgba(0,0,0,0.75);
  border-radius: 5px;
}